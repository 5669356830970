import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import RadioBoxInput from '../../../components/RadioBoxInput';
import Button from '../../../components/Button';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAlert } from '../../../hooks/useAlert';
import {
  email,
  phone,
  requiredFieldFunction,
} from '../../../helpers/yupValidations';
import { array, object, string } from 'yup';
import FormInput from '../../../components/FormInput';
import PhoneInput from 'react-phone-number-input/react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { sendHraConsent } from '../actions';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { submitBtnTxt } from '../../../helpers/functions';

export const schema = object().shape({
  language: requiredFieldFunction('Language'),
  viaServiceUse: array().min(1, 'Please select at least one'),
  email: string().when('viaServiceUse', {
    is: (val) => {
      return val?.includes('EMAIL');
    },
    then: () => email,
    otherwise: () => string(),
  }),
  phone: string().when('viaServiceUse', {
    is: (val) => {
      return val?.includes('SMS');
    },
    then: () => phone,
    otherwise: () => string().nullable(),
  }),
});

const SendConsentModal = ({ handleClose, formData }) => {
  const { t } = useTranslation();

  const queryclient = useQueryClient();
  const { showAlert } = useAlert();
  const { state } = useLocation();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      language: 'en',
      viaServiceUse: ['EMAIL'],
      email: '',
      phone: '',
    },
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: sendHraConsent,
    mutationKey: 'sendHraConsent',
    onSuccess: ({ data }) => {
      showAlert({ type: 'success', message: data?.data?.message });
      queryclient.invalidateQueries({
        queryKey: ['HRAList'],
        exact: false,
      });
      handleClose();
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });

  const [sendViaWatch] = watch(['viaServiceUse']);
  const handleCheckboxChange = (value) => {
    const updatedValues = sendViaWatch?.includes(value)
      ? sendViaWatch.filter((v) => v !== value)
      : [...sendViaWatch, value];

    setValue('viaServiceUse', updatedValues, { shouldValidate: true });
  };
  const handleSubmitForm = (_) => {
    const { consumerRelationship, ...rest } = formData || {};
    const { relation, data, isChild } = consumerRelationship || {};
    const { personal_demographic_info } = data || {};
    let payload = {
      consumerRelationship: {
        relation,
        isChild,
        data: {
          personal_demographic_info,
        },
      },
      ...rest,
      hraDocumentInfo: _,
      relationId: consumerRelationship?.relationId || '',
    };

    mutate(payload);
  };
  useEffect(() => {
    if (formData?.consumerRelationship?.relation === 'Self') {
      setValue('viaServiceUse', ['EMAIL', 'SMS']);
      setValue(
        'email',
        formData?.consumerRelationship?.data?.personal_demographic_info
          ?.email || state?.email,
      );
      setValue(
        'phone',
        formData?.consumerRelationship?.data?.personal_demographic_info
          ?.phone || state?.phone,
      );
    } else {
      if (formData?.consumerRelationship?.data?.personal_demographic_info) {
        setValue(
          'email',
          formData?.consumerRelationship?.data?.personal_demographic_info
            ?.email,
        );
        setValue(
          'phone',
          formData?.consumerRelationship?.data?.personal_demographic_info
            ?.phone,
        );
      }
    }
  }, [formData, state]);

  return (
    <div className="w--full d--flex flex--column gap--xl">
      <div className=" w--full d--flex align-items--center justify-content--between gap--lg">
        <label className="label--control font--sm font--500  text--black w-min--100 w--full">
          {t('translation:labels.Language')}
        </label>
        <div className="d--flex gap--md w--full">
          <div className="w-min--85">
            <Controller
              name="language"
              control={control}
              render={({ field }) => (
                <RadioBoxInput
                  {...field}
                  htmlFor="en"
                  label={t('translation:labels.English')}
                  value="en"
                  checked={watch('language') === 'en'}
                />
              )}
            />
          </div>
          <Controller
            name="language"
            control={control}
            render={({ field }) => (
              <RadioBoxInput
                {...field}
                htmlFor="es"
                label={t('translation:labels.Spanish')}
                value="es"
                checked={watch('language') === 'es'}
              />
            )}
          />
        </div>
      </div>

      <div className=" w--full d--flex align-items--center justify-content--between gap--lg">
        <label className="label--control font--sm font--500  text--black w-min--100 w--full">
          {t('translation:labels.Send Via')}
        </label>
        <div className="d--flex gap--md w--full">
          <div className="d--flex gap--sm">
            <input
              onChange={() => handleCheckboxChange('EMAIL')}
              type="checkbox"
              id="EMAIL"
              className="form-check-input"
              checked={sendViaWatch?.includes('EMAIL')}
              name="viaServiceUse"
            />
            <label htmlFor="EMAIL" className="form-check-label">
              {t('translation:labels.Email')}
            </label>
          </div>
          <div className="d--flex gap--sm">
            <input
              onChange={() => handleCheckboxChange('SMS')}
              type="checkbox"
              id="SMS"
              checked={sendViaWatch?.includes('SMS')}
              name="viaServiceUse"
            />
            <label htmlFor="SMS" className="form-check-label">
              SMS
            </label>
          </div>
        </div>
      </div>
      {errors?.viaServiceUse?.message && (
        <div className="d--flex gap--md w--full">
          <div className="w--full"></div>

          <div
            className={`label--control font--sm font--500 m-b--sm
          text--danger justify-content--end w--full`}
          >
            {t(`translation:errors.${errors?.viaServiceUse?.message}`)}
          </div>
        </div>
      )}
      <div className="d--flex gap--lg w--full">
        {sendViaWatch?.includes('EMAIL') ? (
          <div className="w--full">
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  label="Email"
                  placeholder="Enter Email Address"
                  error={errors?.email?.message}
                  height="40"
                />
              )}
            />
          </div>
        ) : null}
        {sendViaWatch?.includes('SMS') ? (
          <div className="w--full">
            <label
              className={`label--control font--sm font--500 m-b--sm d--inline-block ${
                errors?.phone?.message ? 'text--danger' : 'text--grey'
              }`}
            >
              {errors?.phone?.message
                ? t(`translation:errors.${errors?.phone?.message}`)
                : t(`translation:labels.Phone`)}
              <span className=" font--sm text--danger ">*</span>
            </label>
            <PhoneInput
              name="phone"
              control={control}
              defaultCountry={'US'}
              international={true}
              withCountryCallingCode={true}
              internationalIcon={() => (
                <span>
                  <i className="fa fa-phone icon-circle bg-danger"></i>
                </span>
              )}
              limitMaxLength={true}
              className={`form--control w--full h-min--36  radius--md p-l--md p-r--md  d--flex align-items--center ${
                errors?.phone?.message
                  ? 'border-full--danger'
                  : 'border-full--black-200'
              }`}
            />
          </div>
        ) : null}
      </div>

      <div className=" d--flex align-items--center justify-content--end m-t--lg gap--sm">
        <Button
          btnClasses="btn  w-max--150"
          variant="primary"
          color="black"
          type="button"
          onClick={() => {
            handleSubmit(handleSubmitForm)();
          }}
        >
          {submitBtnTxt(isLoading, t('translation:button.Send'))}
        </Button>
      </div>
    </div>
  );
};

export default SendConsentModal;
