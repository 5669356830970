import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import RadioBoxInput from '../../../components/RadioBoxInput';
import Button from '../../../components/Button';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAlert } from '../../../hooks/useAlert';
import { requiredFieldFunction } from '../../../helpers/yupValidations';
import { array, object } from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { sendHraAgreementConsent } from '../actions';
import { useTranslation } from 'react-i18next';
import { submitBtnTxt } from '../../../helpers/functions';

const schema = object().shape({
  language: requiredFieldFunction('Language'),
  viaServiceUse: array().min(1, 'Please select at least one'),
});

const SendAggrementModal = ({ handleClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { showAlert } = useAlert();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      language: 'en',
      viaServiceUse: ['EMAIL'],
    },
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: sendHraAgreementConsent,
    mutationKey: 'hraAgreementDocumentInfo',
    onSuccess: ({ data }) => {
      showAlert({ type: 'success', message: data?.data?.message });
      queryClient.invalidateQueries({
        queryKey: ['getUserDetails'],
        exact: false,
      });
      handleClose();
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });

  const [sendViaWatch] = watch(['viaServiceUse']);
  const handleCheckboxChange = (value) => {
    const updatedValues = sendViaWatch?.includes(value)
      ? sendViaWatch.filter((v) => v !== value)
      : [...sendViaWatch, value];

    setValue('viaServiceUse', updatedValues, { shouldValidate: true });
  };
  const handleSubmitForm = (_) => {
    mutate({
      hraAgreementDocumentInfo: _,
    });
  };

  return (
    <form
      className="w--full d--flex flex--column gap--xl"
      autoComplete="off"
      onSubmit={handleSubmit(handleSubmitForm)}
    >
      <div className=" w--full d--flex align-items--center justify-content--between gap--lg">
        <label className="label--control font--sm font--500  text--black w-min--100 w--full">
          {t('translation:labels.Language')}
        </label>
        <div className="d--flex gap--md w--full">
          <div className="w-min--85">
            <Controller
              name="language"
              control={control}
              render={({ field }) => (
                <RadioBoxInput
                  {...field}
                  htmlFor="en"
                  label={t('translation:labels.English')}
                  value="en"
                  checked={watch('language') === 'en'}
                />
              )}
            />
          </div>
          <Controller
            name="language"
            control={control}
            render={({ field }) => (
              <RadioBoxInput
                {...field}
                htmlFor="es"
                label={t('translation:labels.Spanish')}
                value="es"
                checked={watch('language') === 'es'}
              />
            )}
          />
        </div>
      </div>

      <div className=" w--full d--flex align-items--center justify-content--between gap--lg">
        <label className="label--control font--sm font--500  text--black w-min--100 w--full">
          {t('translation:labels.Send Via')}
        </label>
        <div className="d--flex gap--md w--full">
          <div className="d--flex gap--sm">
            <input
              onChange={() => handleCheckboxChange('EMAIL')}
              type="checkbox"
              id="EMAIL"
              className="form-check-input"
              checked={sendViaWatch?.includes('EMAIL')}
              name="viaServiceUse"
            />
            <label htmlFor="EMAIL" className="form-check-label">
              {t('translation:labels.Email')}
            </label>
          </div>
          <div className="d--flex gap--sm">
            <input
              onChange={() => handleCheckboxChange('SMS')}
              type="checkbox"
              id="SMS"
              checked={sendViaWatch?.includes('SMS')}
              name="viaServiceUse"
            />
            <label htmlFor="SMS" className="form-check-label">
              SMS
            </label>
          </div>
        </div>
      </div>
      {errors?.viaServiceUse?.message && (
        <div className="d--flex gap--md w--full">
          <div className="w--full"></div>

          <div
            className={`label--control font--sm font--500 m-b--sm
          text--danger justify-content--end w--full`}
          >
            {t(`translation:errors.${errors?.viaServiceUse?.message}`)}
          </div>
        </div>
      )}

      <div className=" d--flex align-items--center justify-content--end m-t--lg gap--sm">
        <Button
          btnClasses="btn  w-max--150"
          variant="primary"
          color="black"
          type="submit"
        >
          {submitBtnTxt(isLoading, t('translation:button.Send'))}
        </Button>
      </div>
    </form>
  );
};

export default SendAggrementModal;
