import { CopyIcon, TickCircleIcon } from '../../../../../assets/icons/useIcons';
import ConsentWebCode from './ConsentWebCode';
import ConsentWebTable from './ConsentWebTable';
import WebhookModeButton from '../../../../../components/WebhookModeButton';
import useCopyText from '../../../../../hooks/useCopyText';
import { useTranslation } from 'react-i18next';

const CheckConsentWebhook = () => {
  const { t } = useTranslation();
  const { copied, handleCopy } = useCopyText();

  return (
    <>
      <div className="d--flex justify-content--end w--full ">
        <div className="w-max--150 d--flex w--full bg--primary border-full--primary radius--md ">
          <WebhookModeButton />
        </div>
      </div>
      <div className="d--flex flex--column gap--lg">
        <div className="d--flex gap--lg w--full h--full flex--column-xs">
          <div className="d--flex flex--column gap--lg w--full">
            <div className="webhook w--full radius--sm border-full--black-100 p--lg  ">
              <div className="d--flex gap--md flex--column">
                <div className=" font--md font--600">
                  {t('translation:consentWebhook.h1')}
                </div>
                <div className="d--flex gap--md p-t---md h-min--60 radius--sm border-full--black-100 word-break--all p--md position--relative">
                  {`${process.env.REACT_APP_BASE_API_URL}/webhook/check-consent`}

                  <div
                    className="position--absolute top---13  right---4 c--pointer text--black-600 bg--white"
                    onClick={() =>
                      handleCopy(
                        `${process.env.REACT_APP_BASE_API_URL}/webhook/check-consent`,
                      )
                    }
                  >
                    {copied ? <TickCircleIcon /> : <CopyIcon />}
                  </div>
                </div>
              </div>
            </div>
            <div className="w--full radius--sm  claimWebhookList ">
              <ConsentWebTable />
            </div>
          </div>

          <ConsentWebCode />
        </div>
      </div>
    </>
  );
};

export default CheckConsentWebhook;
