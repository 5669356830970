import React, { useEffect } from 'react';
import FormSelect from '../../../components/FormSelect';
import { useForm, Controller } from 'react-hook-form';
import RadioBoxInput from '../../../components/RadioBoxInput';
import Button from '../../../components/Button';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getAgencyListDropDown, sendInvite } from '../actions';
import { useAlert } from '../../../hooks/useAlert';
import { npn, requiredFieldFunction } from '../../../helpers/yupValidations';
import { yupResolver } from '@hookform/resolvers/yup';
import { array, object, string } from 'yup';
import FormInput from '../../../components/FormInput';
import { useTranslation } from 'react-i18next';
import { submitBtnTxt } from '../../../helpers/functions';

let agencyTypeLookUp = {
  OWN_AGENCY: 'Own Agency',
  ASSOCIATED_AGENCY: 'Associated Agency',
  INVITED_AGENCY: 'Invited Agency',
};

export const schema = object().shape({
  // agency: requiredFieldFunction('Agency'),
  reason: requiredFieldFunction('Reason'),
  type: requiredFieldFunction('Type'),
  language: requiredFieldFunction('Language'),
  enrollmentYear: requiredFieldFunction('Enrollment Year'),
  viaServiceUse: array().min(1, 'Please select at least one'),
  aorSetting: object({
    NPN: string().when('type', {
      is: (val) => {
        return val === 'OTHER';
      },
      then: () => npn,
      otherwise: () => string(),
    }),
  }),
});

const SendDocModal = ({
  handleClose,
  uuid,
  isSigned,
  editMemberInstance = {},
}) => {
  const { t } = useTranslation();
  const options = [
    {
      label: 'Consent',
      value: 'CMS',
      access:
        editMemberInstance?.editData?.signedDate &&
        !editMemberInstance?.editData?.eligibilitySignedDate
          ? false
          : true,
    },
    {
      label: 'Eligibility',
      value: 'ELIGIBILITY',
      access:
        editMemberInstance?.editData?.signedDate &&
        !editMemberInstance?.editData?.eligibilitySignedDate
          ? true
          : false,
    },
  ];

  const queryclient = useQueryClient();
  const { showAlert } = useAlert();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      reason: 'CONSENT',
      agency: '',
      type:
        editMemberInstance?.editData?.signedDate &&
        !editMemberInstance?.editData?.eligibilitySignedDate
          ? 'ELIGIBILITY'
          : 'CMS',
      language: 'en',
      enrollmentYear: '2024',
      aorSetting: { type: 'SELF', value: true, NPN: '' },
      viaServiceUse: ['EMAIL'],
      isAllowDirectSignature: 'No',
    },
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  });

  const { data: agencyListDropdown } = useQuery({
    queryKey: 'getAgencyListDropDown',
    queryFn: getAgencyListDropDown,
    select: (data) => data?.data?.data,
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: sendInvite,
    mutationKey: 'sendInvite',
    onSuccess: ({ data }) => {
      showAlert({ type: 'success', message: data?.data?.message });
      queryclient.invalidateQueries({
        queryKey: ['getMemberList'],
        exact: false,
      });
      handleClose();
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });
  const [sendViaWatch] = watch(['viaServiceUse']);
  const handleCheckboxChange = (value) => {
    const updatedValues = sendViaWatch?.includes(value)
      ? sendViaWatch.filter((v) => v !== value)
      : [...sendViaWatch, value];

    setValue('viaServiceUse', updatedValues, { shouldValidate: true });
  };
  const onSubmit = (_) => {
    return mutate({
      ..._,
      isAllowDirectSignature:
        _?.isAllowDirectSignature === 'Yes' ? true : false,
      uuid,
    });
  };
  useEffect(() => {
    if (editMemberInstance?.editData?.enrollmentYear) {
      setValue('enrollmentYear', editMemberInstance?.editData?.enrollmentYear);
    }
  }, [editMemberInstance]);

  return (
    <form
      className="w--full d--flex flex--column gap--xl "
      onSubmit={handleSubmit(onSubmit)}
      onClick={(e) => {
        e.stopPropagation();
      }}
      autoComplete="off"
    >
      <div className="d--flex gap--lg w--full">
        <div className="w--full">
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <FormSelect
                {...field}
                label="Document Type"
                error={errors?.type?.message}
                options={options}
                height="40"
              />
            )}
          />
        </div>
        <div className="w--full">
          <Controller
            name="reason"
            control={control}
            render={({ field }) => (
              <FormSelect
                {...field}
                label="Reason"
                error={errors?.reason?.message}
                options={[
                  {
                    label: 'Consent',
                    value: 'CONSENT',
                  },
                  {
                    label: 'App Review',
                    value: 'APP_REVIEW',
                  },
                  {
                    label: 'Change Approval',
                    value: 'CHANGE_APPROVAL',
                  },
                  {
                    label: 'Multi Type',
                    value: 'MULTI_TYPE',
                  },
                ]}
                height="40"
              />
            )}
          />
        </div>
      </div>

      <div className="d--flex gap--lg w--full">
        <div className="w--full">
          {' '}
          <Controller
            name="enrollmentYear"
            control={control}
            render={({ field }) => (
              <FormSelect
                {...field}
                label="Enrollment Year"
                error={errors?.enrollmentYear?.message}
                options={[
                  {
                    label: '2024',
                    value: '2024',
                  },
                  {
                    label: '2025',
                    value: '2025',
                  },
                ]}
                height="40"
                disabled={
                  Boolean(
                    editMemberInstance?.editData?.eligibilitySignedDate,
                  ) || Boolean(!editMemberInstance?.editData?.signedDate)
                    ? false
                    : editMemberInstance?.editData?.enrollmentYear
                }
              />
            )}
          />
        </div>
        <div className="w--full">
          <Controller
            name="agency"
            control={control}
            render={({ field }) => (
              <FormSelect
                {...field}
                placeholder="Select Agency"
                options={
                  agencyListDropdown &&
                  agencyListDropdown?.length > 0 &&
                  agencyListDropdown?.map(
                    ({ agencyName, agencyNPN, type, typeAgency }) => {
                      return {
                        label: `${agencyName} (${agencyTypeLookUp[type]})`,
                        value: `${agencyName}_${agencyNPN}_${typeAgency}`,
                      };
                    },
                  )
                }
                label="Agency"
                error={errors?.agency?.message}
                required={false}
              />
            )}
          />
        </div>
      </div>

      <div className=" w--full d--flex align-items--center justify-content--between gap--lg">
        <label className="label--control font--sm font--500  text--black w-min--100 w--full">
          {t('translation:labels.Language')}
        </label>
        <div className="d--flex gap--md w--full">
          <div className="w-min--85">
            <Controller
              name="language"
              control={control}
              render={({ field }) => (
                <RadioBoxInput
                  {...field}
                  htmlFor="en"
                  label={t('translation:labels.English')}
                  value="en"
                  checked={watch('language') === 'en'}
                />
              )}
            />
          </div>
          <Controller
            name="language"
            control={control}
            render={({ field }) => (
              <RadioBoxInput
                {...field}
                htmlFor="es"
                label={t('translation:labels.Spanish')}
                value="es"
                checked={watch('language') === 'es'}
              />
            )}
          />
        </div>
      </div>
      <div className=" w--full d--flex align-items--center justify-content--between gap--lg">
        <label className="label--control font--sm font--500  text--black w-min--100 w--full">
          {t('translation:labels.AOR')}
        </label>
        <div className="d--flex gap--md w--full">
          <div className="w-min--85">
            <Controller
              name="aorSetting.type"
              control={control}
              render={({ field }) => (
                <RadioBoxInput
                  {...field}
                  htmlFor="SELF"
                  label="Self"
                  value="SELF"
                  checked={watch('aorSetting.type') === 'SELF'}
                  onChange={(e) => {
                    field.onChange(e);
                    setValue('aorSetting.NPN', '');
                  }}
                />
              )}
            />
          </div>
          <Controller
            name="aorSetting.type"
            control={control}
            render={({ field }) => (
              <RadioBoxInput
                {...field}
                htmlFor="OTHER"
                label="Other"
                value="OTHER"
                checked={watch('aorSetting.type') === 'OTHER'}
              />
            )}
          />
        </div>
      </div>

      {watch('aorSetting.type') === 'OTHER' && (
        <div className="d--flex gap--lg w--full">
          <div className="w--full"> </div>
          <div className="w--full">
            <Controller
              name="aorSetting.NPN"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  placeholder="Enter NPN"
                  // label="NPN"
                  error={errors?.aorSetting?.NPN?.message}
                />
              )}
            />
          </div>
        </div>
      )}

      <div className=" w--full d--flex align-items--center justify-content--between gap--lg">
        <label className="label--control font--sm font--500  text--black w-min--100 w--full">
          {t('translation:labels.Send Via')}
        </label>
        <div className="d--flex gap--md w--full">
          <div className="d--flex gap--sm">
            <input
              onChange={() => handleCheckboxChange('EMAIL')}
              type="checkbox"
              value="EMAIL"
              className="form-check-input"
              checked={sendViaWatch?.includes('EMAIL')}
            />
            <label className="form-check-label">
              {' '}
              {t('translation:labels.Email')}
            </label>
          </div>
          <div className="d--flex gap--sm">
            <input
              onChange={() => handleCheckboxChange('SMS')}
              type="checkbox"
              value="SMS"
              checked={sendViaWatch?.includes('SMS')}
            />
            <label className="form-check-label">SMS</label>
          </div>
        </div>
      </div>
      {errors?.viaServiceUse?.message && (
        <div className="d--flex gap--md w--full">
          <div className="w--full"></div>

          <div
            className={`label--control font--sm font--500 m-b--sm
          text--danger justify-content--end w--full`}
          >
            {errors?.viaServiceUse?.message}
          </div>
        </div>
      )}
      <div className=" w--full d--flex align-items--center justify-content--between gap--lg">
        <label className="label--control font--sm font--500  text--black w-min--100 w--full">
          {t('translation:labels.AllowWihtoutOTP')}
        </label>
        <div className="d--flex gap--md w--full">
          <div className="w-min--85">
            <Controller
              name="isAllowDirectSignature"
              control={control}
              render={({ field }) => (
                <RadioBoxInput
                  {...field}
                  label=                      {t('translation:labels.Yes')}

                  value="Yes"
                  checked={watch('isAllowDirectSignature') === 'Yes'}
                />
              )}
            />
          </div>
          <Controller
            name="isAllowDirectSignature"
            control={control}
            render={({ field }) => (
              <RadioBoxInput
                {...field}
                value="No"
                label="No"
                checked={watch('isAllowDirectSignature') === 'No'}
              />
            )}
          />
        </div>
      </div>

      <div className=" d--flex align-items--center justify-content--end m-t--lg gap--sm">
        <Button
          btnClasses="btn  w-max--150"
          variant="primary"
          color="black"
          disabled={isLoading}
          type="submit"
        >
          {submitBtnTxt(isLoading, t('translation:button.Send'))}
        </Button>
      </div>
    </form>
  );
};

export default SendDocModal;
