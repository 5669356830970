import { apiClient } from '../../api/axios';
import { API_ROUTES } from './constants';

export const createWebhookConsentObj = (data) => {
  return apiClient({
    method: API_ROUTES.CREATE_CONSENT.METHOD,
    url: API_ROUTES.CREATE_CONSENT.URL,
    // url: `${API_ROUTES.CREATE_CONSENT.URL}${id}`,
    data: data,
  });
};
export const agentConsenList = (data) => {
  return apiClient({
    method: API_ROUTES.AGENT_CONSENT_LIST.METHOD,
    url: API_ROUTES.AGENT_CONSENT_LIST.URL,
    data,
  });
};
export const checkConsentWebhookObj = (data) => {
  return apiClient({
    method: API_ROUTES.CHECK_CONSENT.METHOD,
    url: API_ROUTES.CHECK_CONSENT.URL,
    data: data,
  });
};
export const checkConsenList = (data) => {
  return apiClient({
    method: API_ROUTES.CHECK_CONSENT_LIST.METHOD,
    url: API_ROUTES.CHECK_CONSENT_LIST.URL,
    data,
  });
};
export const retrieveConsumer = (data) => {
  return apiClient({
    method: API_ROUTES.RETRIEVE_CONSUMER.METHOD,
    url: API_ROUTES.RETRIEVE_CONSUMER.URL,
    data,
  });
};
export const retrieveConsumerList = (data) => {
  return apiClient({
    method: API_ROUTES.RETRIEVE_CONSUMER_LIST.METHOD,
    url: API_ROUTES.RETRIEVE_CONSUMER_LIST.URL,
    data,
  });
};
export const fetchAgent = (data) => {
  return apiClient({
    method: API_ROUTES.FETCH_AGENT.METHOD,
    url: API_ROUTES.FETCH_AGENT.URL,
    data,
  });
};
export const fetchAgentList = (data) => {
  return apiClient({
    method: API_ROUTES.FETCH_AGENT_LIST.METHOD,
    url: API_ROUTES.FETCH_AGENT_LIST.URL,
    data,
  });
};
