import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input/react-hook-form';
import FormInput from '../../components/FormInput';
import { useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { defaultValues, schema } from './constants';
import { useMutation } from '@tanstack/react-query';
import { signUp } from './actions';
import { submitBtnTxt } from '../../helpers/functions';
import OTPVerify from '../OTPVerify';
import { useAlert } from '../../hooks/useAlert';
import SubmitButton from '../../components/Button/SubmitButton';
import { AGENT, MEMBER } from '../../helpers/constants';
import OuterHeader from '../Login/Components/OuterHeader';
import { useTranslation } from 'react-i18next';

const SignUp = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { userType = AGENT } = state || {};
  const navigate = useNavigate();
  const { showAlert } = useAlert();

  // const [showOtp, setShowOtp] = useState(true);
  const [showOtp, setShowOtp] = useState(false);
  const [formData, setFormData] = useState(false);

  const { mutate, isLoading } = useMutation({
    mutationFn: signUp,
    onSuccess: ({ data }) => {
      setShowOtp(true);
      showAlert({ type: 'success', message: data?.data?.message });
      setFormData(data?.data);
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });

  const {
    control,
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  });

  const onSubmit = (_) => {
    const { terms, ...rest } = _ || {};
    mutate({ ...rest, userType });
  };

  return (
    <>
      <OuterHeader showTabs={false} />

      <div className="w--full  d--flex flex--column align-items--center justify-content--start mainSign loginPage bg--contrast overflow--y-auto">
        {/* <div className="w--full h-min--60 d--flex justify-content--between align-items--center bg--white p-t--xs p-l--md p-r--md border-bottom--black-50">
          <img src={nextereLogo} alt="nextereLogo" className="w-max--150" />
        </div> */}
        <div
          className={`w--full h--full p-t--xl d--flex align-items--center gap--lg p-b--xl flex--column loginContent p-l--sm p-r--sm ${
            !showOtp && 'justify-content--center'
          }`}
        >
          {/* <div className="w--full gap--sm d--flex flex--column align-items--center justify-content--center m-b--sm">
            <h3 className="font--2xl text--primary ">
              <img src={nextereLogo} alt="nextereLogo" className="w-max--150" />
            </h3>
          </div> */}

          {showOtp ? (
            <OTPVerify {...{ formData, setShowOtp, userType }} />
          ) : (
            <div className="radius--md  gap--xl w-max--600 w--full bg--secondary p--xl z-index--xs bg--white box--shadow d--flex align-items--center justify-content--center flex--column gap--xs h-min--300">
              <div className="w--full">
                <form
                  className="w--full d--flex flex--column gap--sm"
                  onSubmit={handleSubmit(onSubmit)}
                  autoComplete="off"
                >
                  <div className="w--full orText border-top--black-200 m-t--md m-b--xl d--flex align-items--center justify-content--center">
                    <span className="w-min--60 font--md font--600 text--primary p--md bg--white d--flex justify-content--center align-items--center position--absolute top:--5">
                      {t('translation:Signup.Signup')}
                      {'  '}
                      {userType === MEMBER ? 'as Consumer' : 'as Agent/Agency'}
                    </span>
                  </div>
                  <div className="w--full overflow--y-auto">
                    <div className="w--full d--flex gap--lg m-b--sm flex--column-xs">
                      <Controller
                        name="firstName"
                        control={control}
                        render={({ field }) => (
                          <FormInput
                            {...field}
                            label="First Name"
                            error={errors?.firstName?.message}
                            height="40"
                          />
                        )}
                      />
                      <Controller
                        name="middleName"
                        control={control}
                        render={({ field }) => (
                          <FormInput
                            {...field}
                            type="text"
                            label="Middle Name"
                            error={errors?.middleName?.message}
                            height="40"
                            required={false}
                          />
                        )}
                      />
                    </div>
                    <div className="w--full d--flex gap--lg m-b--sm flex--column-xs">
                     
                      <Controller
                        name="lastName"
                        control={control}
                        render={({ field }) => (
                          <FormInput
                            {...field}
                            label="Last Name"
                            error={errors?.lastName?.message}
                            height="40"
                          />
                        )}
                      />
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <FormInput
                            {...field}
                            label="Email"
                            error={errors?.email?.message}
                            height="40"
                          />
                        )}
                      />
                    </div>
                    <div className="w--full d--flex gap--lg m-b--sm flex--column-xs">
                      <div className="w--full">
                        <label
                          className={`label--control font--sm font--500 m-b--sm d--inline-block ${
                            errors?.phone?.message
                              ? 'text--danger'
                              : 'text--grey'
                          }`}
                        >
                          {errors?.phone?.message ??
                            t(`translation:labels.Phone`)}
                        </label>
                        <PhoneInput
                          name="phone"
                          control={control}
                          defaultCountry={'US'}
                          international={true}
                          withCountryCallingCode={true}
                          placeholder="Enter your phone"
                          internationalIcon={() => (
                            <span>
                              <i className="fa fa-phone icon-circle bg-danger"></i>
                            </span>
                          )}
                          limitMaxLength={true}
                          className={`form--control w--full h-min--36  radius--sm p-l--md p-r--md  d--flex align-items--center ${
                            errors?.phone?.message
                              ? 'border-full--danger'
                              : 'border-full--black-200'
                          }`}
                          onCountryChange={(_) => {
                            setValue('country', _);
                          }}
                        />
                      </div>
                      <div className="w--full"></div>
                    </div>
                  </div>
                  <div className="w--full ">
                    <label className="label--control font--2sm font--500 m-b--sm   text--grey d--flex align-items--center c--pointer">
                      <input {...register('terms')} type="checkbox" />
                      {t('translation:Signup.l1')}

                      <span
                        className="text--primary c--pointer m-l--xs"
                        onClick={() =>
                          window.open('https://www.nextere.com/privacy-policy')
                        }
                      >
                        {t('translation:Signup.l2')}
                      </span>
                    </label>
                    <div
                      className={`label--control font--2sm font--500 m-b--sm  
         text--danger
          }`}
                    >
                      {errors?.terms?.message &&
                        t(`translation:errors.${errors?.terms?.message}`)}
                    </div>
                    {/* https://www.nextere.com/privacy-policy */}
                  </div>
                  <div className=" d--flex align-items--center justify-content--center gap--sm w--full m-t--sm">
                    <SubmitButton
                      btnClasses="btn w-max--170"
                      disabled={isLoading}
                      size="md"
                    >
                      {submitBtnTxt(isLoading, t('translation:button.Next'))}
                    </SubmitButton>
                  </div>
                </form>
              </div>
            </div>
          )}

          {!showOtp && (
            <div className="w--full d--flex align-items--center justify-content--center gap--sm font--sm m-t--md">
              {t('translation:Signup.AlreadyHaveAccount')}?
              <div
                className="text--black font--sm text--primary font--500 d--flex justify-content--center c--pointer"
                onClick={() => navigate('/')}
              >
                {t('translation:button.Login')}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SignUp;
