export const API_ROUTES = {
  CREATE_CONSENT: {
    METHOD: 'POST',
    URL: 'webhook/create',
  },
  AGENT_CONSENT_LIST: {
    METHOD: 'GET',
    URL: 'webhook/agent-consent-list',
  },
  CHECK_CONSENT: {
    METHOD: 'POST',
    URL: 'webhook/check-consent',
  },
  CHECK_CONSENT_LIST: {
    METHOD: 'GET',
    URL: 'webhook/check-consent-list',
  },
  //Retrives Consumer
  RETRIEVE_CONSUMER: {
    METHOD: 'POST',
    URL: 'webhook/retrieve-consumer',
  },
  RETRIEVE_CONSUMER_LIST: {
    METHOD: 'GET',
    URL: 'webhook/retrieve-consumer-list',
  },
  //Fetch Agent
  FETCH_AGENT: {
    METHOD: 'POST',
    URL: 'webhook/fetch-agent',
  },
  FETCH_AGENT_LIST: {
    METHOD: 'GET',
    URL: 'webhook/fetch-agent-list',
  },
};

export const webhoookModeArr = [
  {
    type: 'TEST',
    label: 'Test',
  },
  { type: 'LIVE', label: 'Live' },
];

export const carrierConfigurationTabs = [
  {
    path: 'manage-keys',
    label: 'Manage Key(s)',
  },
  {
    path: 'claim-webhook',
    label: 'Claim Webhook',
  },
  {
    path: 'consent-webhook',
    label: 'Consent Webhook',
  },
  {
    path: 'consent-check',
    label: 'Consent Check',
  },
  {
    path: 'consent-consumer-data',
    label: 'Retrieve Consumer Data',
  },
  {
    path: 'fetch-agent',
    label: 'Fetch Agent of Record',
  },
];
export const agentAgencyConfigurationTabs = [
  {
    path: 'manage-keys',
    label: 'Manage Key(s)',
  },
  {
    path: 'email-integration',
    label: 'Email Integration',
  },
  {
    path: 'storage',
    label: 'Storage',
  },
  {
    path: 'consentWebhook',
    label: 'Consent Create Webhook',
  },
  {
    path: 'checkConsent',
    label: 'Check Consent Webhook',
  },
];
